import Block from "@/components/wrappers/block"
import Blocks from "@/components/Blocks"
import classNames from "classnames"
import styles from "./report-heading.module.css"

export default function ReportHeading({ block }) {
  const settings = {
    "core/heading": { width: "bleed" },
    "core/paragraph": { width: "bleed" }
  }

  return (
    <Block block={block} gap="small" className={classNames(styles.wrapper)}>
      <Blocks blocks={block.innerBlocks} settings={settings} />
    </Block>
  )
}
